// export const scripterServiceBaseUrl = 'https://4p4f0vgfv9.execute-api.us-west-2.amazonaws.com/Dev';
// export const scripterServiceWSBaseUrl = 'wss://rb481uicv9.execute-api.us-west-2.amazonaws.com/development'; 
export const scripterServiceBaseUrl = 'https://40dlmyh4b0.execute-api.us-west-2.amazonaws.com/dev';
export const scripterServiceWSBaseUrl = 'wss://mu8lwa891a.execute-api.us-west-2.amazonaws.com/dev'; 

export const scripterSharedServiceBaseUrl = '';

export const OKTA_CLIENT_ID = "0oa16mo3iyhFOAday0h8";

export const OKTA_ISSUER_URL = "https://ssodev.wbd.com/oauth2/default";

export const OKTA_REDIRECT_URL = window.location.origin + '/callback';

export const OKTA_URL = "https://twdev.oktapreview.com";

export const IDLE_TIMEOUT = "60";

export const IDLE_WARN_TIMEOUT = "30";
export const getFormattedDate = (date, dateField = false) => {
    if (date) {
        // let formattedDate = new Date(date);
        date = new Date(date).toISOString().slice(0, 10);
        let formattedDate = new Date(date+'T00:00:00')
        let year = formattedDate.getFullYear();
        let month = (1 + formattedDate.getMonth()).toString().padStart(2, '0');
        let day = formattedDate.getDate().toString().padStart(2, '0');
        if (dateField)
            return year + '-' + month + '-' + day;
        else
            return month + '/' + day + '/' + year;
    }
}
export const getAge = (currentDate) => {
    if (currentDate) {
        var today = new Date();
        var birthDate = new Date(currentDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export const formatStartAndEndDate = (start_date, end_date) => {
    var start_date = getFormattedDate(start_date);
    var end_date = getFormattedDate(end_date);
    if (start_date && end_date) {
        return `${start_date} - ${end_date}`;
    }
    if (start_date) {
        return start_date + " Present";
    }
    if (!start_date && !end_date) {
        return ""
    }
}

export const getAddress = (field, item, value, addressConfig) => {
    item[field] = value;
    let a = value.split('\n');
    item[addressConfig.address1] = !(a[0]) ? null : a[0];
    item[addressConfig.address2] = !(a[1]) ? null : a[1];
    let temp = '';
    for (var i = 2; i < a.length; i++) {
        temp += a[i];
    }
    item[addressConfig.address3] = !temp ? null : temp;
    return item;
}

export const getFormattedTime = (date) => {
    date = date?.replace('Z','');
    let formattedDate = new Date(date);
    let hours = formattedDate.getHours();
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12 || 12;
    hours = hours < 10 ? '0' + hours : hours;
    let mins = formattedDate.getMinutes();
    mins = mins < 10 ? '0' + mins : mins;
    return hours + ':' + mins + " " + ampm;
}

export const getDateAndTime = (dateEntry) => {
    let date = getFormattedDate(dateEntry);
    let time = getFormattedTime(dateEntry);
    return date + ", " + time;
}

export const validateEmail = (email) => {
    var emailRegex = /^[A-Za-z0-9]+([\.-][A-Za-z0-9]+)*@[A-Za-z0-9]+([\.-][A-Za-z0-9]+)*(\.[A-Za-z]{2,3})+$/;
    return emailRegex.test(email);
}

export const maskSsn = (ssn) => {
    return ssn ? `xxx-xxx-${ssn?.substr(ssn?.length - 4)}` : ssn;
}

// export const validatePhone = (phone) => {
//     let phoneRegex = /^[0-9\-\(\)+]+$/;
//     return phoneRegex.test(phone);
// }
export const validatePlusCharacter = (text) => {
    let charRegex = /^[+]/;
    return charRegex.test(text);
}

export const validateUSNumber = (text) => {
    let charRegex = /\(\d{3}\) \d{3}-\d{4}$/;
    return charRegex.test(text);
}

export const validatePhoneNumber = (text) => {
    let charRegex = /^\d{10}$/;
    return charRegex.test(text);
}

export const validateFiveDigitNumOnly = (text) => {
    let charRegex = /^[0-9]{1,5}/;
    return charRegex.test(text);
}


export const validateIntlNumber = (text) => {
    let charRegex = /\+\d{1,20}$/;
    return charRegex.test(text);
}

export const formatPhoneNumbers = (phone) => {
    if (phone.length === 10 && validatePhoneNumber(phone) ) {
        return '(' + phone.substr(0, 3) + ')' + ' ' + phone.substr(3, 3) + '-' + phone.substr(6, 4);
    } else {
        return phone
    }
}

export const validateCharctersOnly = (text) => {
    let regex = /^[a-zA-Z]*$/;
    return regex.test(text);
}

export const validateCharctersSpaceOnly = (text) => {
    let regex = /^[a-zA-Z ]*$/;
    return regex.test(text);
}

export const validateNumbersOnly = (text) => {
    let regex = /^[0-9]*$/;
    return regex.test(text);
}

export const validateDecimalNumbers = (text) => {
    let regex = /^([0-9]*\.?[0-9]*|\.[0-9]*)$/;
    console.log("decimal check", regex.test(text))
    return regex.test(text);
}

export const validateCharNumOnly = (text) => {
    let regex = /^[a-zA-Z0-9]*$/i;
    return regex.test(text);
}

export const validateCharNumHyphenSlashOnly = (text) => {
    let regex = /^[a-zA-Z0-9-/]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphenSlash = (text) => {
    let regex = /^[a-zA-Z0-9-/]*$/i;
    return regex.test(text);
}

export const validateCharNumWithHyphen = (text) => {
    let regex = /^[a-zA-Z0-9-]*$/i;
    return regex.test(text);
}

export const validateCharNumCommaDotWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9,. ]*$/i;
    return regex.test(text);
}

export const validateCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9 ]+\s?[a-zA-Z0-9 ]*$/;
    return text ? regex.test(text) : true;
}

export const validateNumPlusOnly = (text) => {
    let regex = /^[0-9+]*$/;
    return regex.test(text);
}

export const validateCharNumHashOnly = (text) => {
    let regex = /^[a-zA-Z0-9#]*$/i;
    return regex.test(text);
}

export const validateCharNumRateOnly = (text) => {
    let regex = /^[a-zA-Z0-9@]*$/i;
    return regex.test(text);
}

export const validateCharNumHashSpaceOnly = (text) => {
    // let regex = /^[a-zA-Z0-9#]+\s?[a-zA-Z0-9#]*$/i;
    let regex = /^[a-zA-Z0-9# ]/i;
    return regex.test(text);
}

export const validateNumHyphenOnly = (text) => {
    let regex = /^[0-9-]*$/;
    return regex.test(text);
}

export const validateAlpVarCharNumOnlyWithSpace = (text) => {
    let regex = /^[a-zA-Z0-9ÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
    return regex.test(text);
}

export const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export const checkValidEditorText = (value) => {
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        return false
    } else {
        return true
    }
}
import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import { submissionAssignedAnalystList } from '../../../../constants/TabList';
import TabsComponent from '../../../SharedComponents/Tabs/Tabs';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import MainViewModalComponent from '../../../../Common/MainViewModalLayout/Modal/Modal';
import { getFormattedDate } from '../../../../Common/Helper';
import MessageModal from '../../../SharedComponents/MessageModal';
import { submissionWorkflowMockData } from './Config';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';

class AssignedAnalystDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: null,
            showReviseCoverageModal: false,
            showConfirmCoverageModal: false,
            isPosting: false,
            tabList: JSON.parse(JSON.stringify(submissionAssignedAnalystList))
        }
    }

    componentDidMount() {
        this.filterTabList()
    }

    filterTabList = () => {
        let tabList = [...this.state.tabList]
        switch (this.props?.coverageDetails.coverage_type_name) {
            case "Amended Writing Credit Determination":
            case "Extended Coverage":
            // case "Memo / Report":
            case "Project":
            case "Quality of Writing":
            case "Regular":
            case "Project Regular":
            // case "Chapter Breakdown":
                tabList = tabList.filter(item => item.value !== 2)
                break;
            case "Capsule Synopsis Only":
            // case "Character Analysis":
            case "Chapter Breakdown":
            case "Memo / Report":
                tabList = tabList.filter(item => item.value === 1)
                break;
            case "Legal":
            case "Legal Amendment":
            case "Legal Addendum":
                tabList = tabList.filter(item => item.value != 2)
                let synopsisTabIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[synopsisTabIndex].label = "Analysis"
                break;
            case "Character Analysis":
                tabList = tabList.filter(item => item.value === 1)
                let firstTabIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[firstTabIndex].label = "Character Analysis"
                break;
            case "Scene Breakdown":
                tabList = tabList.filter(item => item.value != 2)
                let tabIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[tabIndex].label = "Scene Breakdown"
                break;
            case "Second Opinion":
            case "Second Opinion Project":
                tabList = tabList.filter(item => item.value === 3)
                let objIndexTab = tabList.findIndex((obj => obj.value == 3));
                tabList[objIndexTab].label = "Second Opinion"
                break;
            case "Third Opinion":
                tabList = tabList.filter(item => item.value === 3)
                let indObj = tabList.findIndex((obj => obj.value == 3));
                tabList[indObj].label = "Third Opinion"
                break;
            case "Suggested Cuts":
                tabList = tabList.filter(item => item.value != 2)
                let indexObj = tabList.findIndex((obj => obj.value == 1));
                tabList[indexObj].label = "Suggested Cuts"
                break;
            case "Title Search Suggestions":
                tabList = tabList.filter(item => item.value != 2)
                let tabObjIndex = tabList.findIndex((obj => obj.value == 1));
                tabList[tabObjIndex].label = "Title Search Suggestions"
                break;
            case "Top Sheet Comparison":
                tabList = tabList.filter(item => item.value !== 1)
                break;
            case "Writing Credit Determination":
                tabList = tabList.filter(item => item.value !== 2)
                break;
        }
        this.setState({ tabList: tabList, tabValue: tabList[0]?.value })
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    handleShowReviseCoverageModal = () => {
        this.setState({
            showReviseCoverageModal: true
        })
    }

    handleConfirmCoverageModal = () => {
        this.setState({
            showConfirmCoverageModal: true
        })
    }

    handleReturnToStoryDept = () => {
        this.setState({ isPosting: true })
        let postJson = this.getPostJsonData("RETURN TO STORY DEPARTMENT");
        this.postWorkflowSubmission(postJson, null, true, 'submit_coverage');
    }

    handleConfirmCoverage = () => {
        this.setState({ isPosting: true })
        let postJson;
        const containsGroupName = this.props?.submissionSubmittedTo?.some(item => item.group_name === 'Creative Group - President');
        if(containsGroupName){
            postJson = this.getPostJsonData("APPROVE COVERAGE PDF");
            this.handleConfirmCoveragePdf(postJson,'showConfirmCoverageModal')
        }else {
            postJson = this.getPostJsonData("APPROVE COVERAGE");
            this.postWorkflowSubmission(postJson, 'showConfirmCoverageModal', true, 'approve_coverage');
        }
    }

    handleConfirmCoveragePdf = (json, modal)=>{
        let reportName = null;
            if (this.props?.coverage?.coverage_type_name === "Memo / Report") {
                reportName = "MemoReport";
            } else {
                reportName = this.props?.formatCoverageType();
            }
        let load = {
            "payload": 'generateReport',
            "reportName": `rptSc${reportName}`,
            "searchJson": btoa(JSON.stringify(json)),
            "showHtml": 0,
            "isStaticReport": 0,
            "encryptionType": 1,
            "tenantId": this.props?.userContext?.active_tenant?.tenant_id,
            "divisionId": this.props?.divisionId
        }
        ScripterService.getDataWS(load).then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if (response?.statusCode == 200 && response.body == "Email sent successfully"){
                    let postJson = this.getPostJsonData("APPROVE COVERAGE");
                    this.postWorkflowSubmission(postJson, 'showConfirmCoverageModal', true, 'approve_coverage');
                }else if(response.body == "Something went wrong"){
                    this.setState({ isPosting: false, [modal]: false })
                    this.props?.manageMessageModal('Fail', true)
                }
            }
        })
    }
    getPostJsonData = (actionLabel) => {
        let postJson = { ...submissionWorkflowMockData };
        postJson.submission_id = this.props?.submissionId;
        postJson.submission_coverage_id = this.props?.coverageDetails?.submission_coverage_id || this.props?.coverageId;
        postJson.action_label = actionLabel;
        postJson.created_at = new Date().toLocaleString('en-US', { hour12: false});

        return postJson
    }

    postWorkflowSubmission = (postJson, modal, refreshSubmissionPage = false, actionLabel) => {
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
            this.props.userContext.active_tenant.tenant_id, this.props?.divisionId)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isPosting: false, [modal]: false })
                    this.props?.manageMessageModal('Fail', true)
                } else {
                    this.setState({ isPosting: false, [modal]: false });
                    this.props?.manageMessageModal('Success', true, refreshSubmissionPage, actionLabel)
                    this.props?.handlePageRefresh('coverage')
                }
            },
                (err) => {
                    console.log("Error in approving coverage:", err)
                    this.props?.manageMessageModal('Fail', true)
                })
    }

    getGenreName = (genreList) => {
        let genres = [];
        genreList?.map(item => {
            genres?.push(item.genre_name)
        })
        return genres?.join(', ');
    }

    render() {
        const { coverageDetails } = this.props;
        let userPermissions = this.props?.userContext?.active_tenant?.permissions;
        // let nonDeprecatedView = this.props?.confidential ?
        //                         (userPermissions?.canViewConfidentialSubmissions || this.props?.coverageDetails?.analyst_id === this.props?.userContext?.user_profile?.user_id) :
        //                         true
        // let nonDeprecatedView = !this.props?.coverageDetails?.deprecated_view ? 
        //     (this.props?.userContext?.user_profile?.group_name === 'Feature Story Admin' || (this.props?.coverageDetails?.analyst_id === this.props?.userContext?.user_profile?.user_id)) : 
        //     false;
        let nonDeprecatedView = (this.props?.coverageDetails?.analyst_id === this.props?.userContext?.user_profile?.user_id) ?
            true :
            (this.props?.coverageDetails?.deprecated_view === 0 ? true : false);
        return (
            <MDBContainer fluid className="assignedAnalystDetails p-0">
                <MDBRow>
                    <MDBCol md={1} className="date-sent">
                        <BasicLabel text={"Date Sent"} />
                        <p>{getFormattedDate(coverageDetails?.coverage_created, false)}</p>
                    </MDBCol>
                    <MDBCol md={1} className="p-0 due-date">
                        <BasicLabel text={"Due Date"} />
                        <p>{getFormattedDate(coverageDetails?.due_date, false) || "-"}</p>
                    </MDBCol>
                    {/* <MDBCol md={1} className="p-0 due-date">
                        <BasicLabel text={"Return Date"} />
                        <p>{getFormattedDate(coverageDetails?.return_date, false) || "-"}</p>
                    </MDBCol> */}
                    {/* {userPermissions?.canGetAssignedToSubmissionAndSubmitCoverage ? */}
                    {nonDeprecatedView ?
                        <>
                            <MDBCol md={6} className="p-0 assigned-notes">
                                <BasicLabel text={"Assigned Message"} />
                                <p>{coverageDetails?.notes || "-"}</p>
                            </MDBCol>
                            {/* <MDBCol className="p-0 edit-button flex">
                                {this.props?.coverageDetails?.is_edit_coverage ?
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Edit"}
                                        onClick={this.props.handleEdit}
                                        icon={"pen"}
                                        disabled={false}
                                    /> : ""
                                }
                            </MDBCol> */}
                        </> : ""
                    }
                </MDBRow>
                {/* {userPermissions?.canGetAssignedToSubmissionAndSubmitCoverage ? */}
                {nonDeprecatedView ?
                    <>
                        <MDBRow className="genre-section">
                            {(coverageDetails?.coverage_type_name != "Amended Writing Credit Determination" &&
                                coverageDetails?.coverage_type_name != "Legal Addendum" &&
                                coverageDetails?.coverage_type_name != "Legal Amendment" &&
                                coverageDetails?.coverage_type_name != "Legal" &&
                                coverageDetails?.coverage_type_name != "Memo / Report" &&
                                coverageDetails?.coverage_type_name != "Writing Credit Determination") ?
                                <div className="genre-section-width">
                                    <MDBRow>
                                        <MDBCol>
                                            <BasicLabel text={"Genre"} />
                                            <p>{this.getGenreName(coverageDetails?.genre_list) || "-"}</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol>
                                            <BasicLabel text={"Time"} />
                                            <p className="white-space-pre-line">{coverageDetails?.time || "-"}</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol>
                                            <BasicLabel text={"Locale"} />
                                            <p className="white-space-pre-line">{coverageDetails?.locale || "-"}</p>
                                        </MDBCol>
                                    </MDBRow>
                                </div> : null
                            }
                            <div
                                className={`${(coverageDetails?.coverage_type_name != "Amended Writing Credit Determination" &&
                                    coverageDetails?.coverage_type_name != "Legal Addendum" &&
                                    coverageDetails?.coverage_type_name != "Legal Amendment" &&
                                    coverageDetails?.coverage_type_name != "Legal" &&
                                    coverageDetails?.coverage_type_name != "Memo / Report" &&
                                    coverageDetails?.coverage_type_name != "Writing Credit Determination") ? 'logline-sec-width' : ''}`}
                            >
                                <MDBRow>
                                    <MDBCol>
                                        <BasicLabel
                                            text={coverageDetails?.coverage_type_name == "Amended Writing Credit Determination" ||
                                                coverageDetails?.coverage_type_name == "Legal Addendum" ||
                                                coverageDetails?.coverage_type_name == "Legal Amendment" ||
                                                coverageDetails?.coverage_type_name == "Legal" ||
                                                coverageDetails?.coverage_type_name === "Memo / Report" ||
                                                coverageDetails?.coverage_type_name === "Writing Credit Determination" ? "Assignment" : "Logline"}
                                        />
                                        {/* <p className='textareatext'>{coverageDetails?.logline || "-"}</p> */}
                                        <MDBRow className="logline-content">
                                            <MDBCol className="p-0 rich-text-content">
                                                <p className="remove-rte-margin" dangerouslySetInnerHTML={{ __html: this.props?.sanitizer(coverageDetails?.logline) }} />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                    {(coverageDetails?.coverage_type_name === 'Extended Coverage' ||
                                        coverageDetails?.coverage_type_name === 'Quality of Writing' ||
                                        coverageDetails?.coverage_type_name === 'Project Regular' ||
                                        coverageDetails?.coverage_type_name === 'Regular' ||
                                        coverageDetails?.coverage_type_name === 'Second Opinion' ||
                                        coverageDetails?.coverage_type_name === 'Third Opinion' ||
                                        coverageDetails?.coverage_type_name === 'Top Sheet Comparison') ?
                                        <MDBCol>
                                            <MDBRow>
                                                <MDBCol>
                                                    <BasicLabel text={"Recommendation Summary"} />
                                                    <p className="white-space-pre-line">{coverageDetails?.recommendation_summary || "-"}</p>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol>
                                                    <BasicLabel text={"Writer Summary"} />
                                                    <p className="white-space-pre-line">{coverageDetails?.writer_summary || "-"}</p>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol>
                                                    <BasicLabel text={"Recommendation"} />
                                                    <p>{coverageDetails?.recommendation_name || "-"}</p>
                                                </MDBCol>
                                                <MDBCol>
                                                    <BasicLabel text={"Writer Recommendation"} />
                                                    <p>{coverageDetails?.writer_recommendation_name || "-"}</p>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol> : null
                                    }
                                </MDBRow>
                            </div>
                        </MDBRow>
                        <MDBRow className="tabs-section">
                            <MDBCol className="tab-content">
                                <TabsComponent
                                    tabList={this.state.tabList}
                                    value={this.state.tabValue}
                                    onChange={(e, v) => { this.handleTabChange(v) }}
                                />
                            </MDBCol>
                            {this.state.tabValue === 1 && (
                                <MDBRow>
                                    <MDBCol className="p-0 rich-text-content">
                                        <p className="remove-rte-margin" dangerouslySetInnerHTML={{ __html: this.props?.sanitizer(coverageDetails?.synopsis) }} />
                                    </MDBCol>
                                </MDBRow>
                            )}
                            {this.state.tabValue === 2 && (
                                <MDBRow>
                                    <MDBCol className="p-0 rich-text-content">
                                        <p className="remove-rte-margin" dangerouslySetInnerHTML={{ __html: this.props?.sanitizer(coverageDetails?.comparison) }} />
                                    </MDBCol>
                                </MDBRow>
                            )}
                            {this.state.tabValue === 3 && (
                                <MDBRow>
                                    <MDBCol className="p-0 rich-text-content">
                                        <p className="remove-rte-margin" dangerouslySetInnerHTML={{ __html: this.props?.sanitizer(coverageDetails?.comments) }} />                           </MDBCol>
                                </MDBRow>
                            )}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={`${(this.props?.coverageDetails?.is_submit_coverage || (this.props?.coverageDetails?.is_revise_coverage &&
                                !this.props?.coverageDetails?.is_confirm_coverage)) ? 9 : 7}`}
                            >
                            </MDBCol>
                            {this.props?.coverageDetails?.is_revise_coverage ?
                                <MDBCol
                                    md={3}
                                    className={`${(this.props?.coverageDetails?.is_revise_coverage &&
                                        !this.props?.coverageDetails?.is_confirm_coverage) ?
                                        "revise-coverage" : "return-revision"}`
                                    }>
                                    <BasicButton
                                        variant="contained"
                                        type="inline"
                                        text={"Return for Revision"}
                                        disabled={false}
                                        icon={"share"}
                                        onClick={this.handleShowReviseCoverageModal}
                                    />
                                </MDBCol> : ""
                            }
                            {this.props?.coverageDetails?.is_confirm_coverage ?
                                <MDBCol className="confirm-btn">
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Confirm"}
                                        onClick={this.handleConfirmCoverageModal}
                                        icon={"check"}
                                        disabled={false}
                                    />
                                </MDBCol> : ""
                            }
                            {this.props?.coverageDetails?.is_submit_coverage ?
                                <MDBCol md={3} className="return-editor">
                                    <BasicButton
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Return to Story Dept"}
                                        disabled={false}
                                        icon={"share"}
                                        onClick={this.handleReturnToStoryDept}
                                    />
                                </MDBCol> : ""
                            }
                        </MDBRow>
                    </> : ""
                }
                {this.state.showReviseCoverageModal &&
                    <MainViewModalComponent
                        open={this.state.showReviseCoverageModal}
                        handleClose={() => this.setState({ showReviseCoverageModal: false })}
                        headerText={"Revise Coverage"}
                        mode={""}
                        messageMode={"revise_coverage"}
                        modalName={"Revise Coverage"}
                        analyst={coverageDetails?.analyst}
                        submissionId={this.props?.submissionId}
                        coverageId={this.props?.coverageDetails?.submission_coverage_id || this.props?.coverageId}
                        handlePageRefresh={this.props?.handlePageRefresh}
                        divisionId={this.props?.divisionId}
                    />
                }
                {this.state.showConfirmCoverageModal &&
                    <MessageModal
                        open={this.state.showConfirmCoverageModal}
                        title={"Approve Coverage"}
                        message={"Are you sure you would like to confirm this coverage? The assigned Story Analyst will be notified. This action cannot be undone."}
                        primaryButtonText={""}
                        primaryConfirmBtnText={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Approve"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.handleConfirmCoverage}
                        handleClose={(e) => this.setState({ showConfirmCoverageModal: false })}
                    />
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(AssignedAnalystDetails)